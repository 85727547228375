















import { Component, Vue, Watch } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import deviceCollection, { Device } from '@/models/device'
import DeviceItem from '@/components/common/DeviceItem.vue'
import { Company } from '@/models/company'
import AuthStore from '@/store/AuthStore'

@Component<MonitorDeviceList>({
  components: {
    DeviceItem
  },
  mounted () {
    this.subscribe()
  },
  destroyed () {
    if (this.unsubscribe) this.unsubscribe()
  }
})
export default class MonitorDeviceList extends Vue {
  unsubscribe: firebase.Unsubscribe | null = null
  docs: firebase.firestore.QueryDocumentSnapshot<Device>[] = []

  @Watch('company')
  onChangeCompany (): void {
    this.subscribe()
  }

  get company (): firebase.firestore.DocumentSnapshot<Company> | null{
    return AuthStore.selectedCompany
  }

  get filteredDocs (): firebase.firestore.QueryDocumentSnapshot<Device>[] {
    return this.docs.filter(doc => {
      const item = doc.data()
      return item.lastSecond.latitude && item.lastSecond.longitude
    })
  }

  get items (): Device[] {
    return this.filteredDocs.map(doc => doc.data())
  }

  subscribe (): void {
    const ref = this.company ? deviceCollection.where('company', '==', this.company.ref) : deviceCollection
    this.unsubscribe = ref.limit(100).onSnapshot(sn => {
      this.docs = sn.docs
    })
  }
}
