











import { Component, Vue } from 'vue-property-decorator'
import AuthStore from '@/store/AuthStore'
import firebase from '@/plugins/firebase'
import { Company } from '@/models/company'

@Component<GroupSelect>({
})
export default class GroupSelect extends Vue {
  get selectedCompany (): firebase.firestore.DocumentSnapshot<Company> | null {
    return AuthStore.selectedCompany
  }

  get selectGroups (): { value: string, text: string }[] {
    const filteredGroups = AuthStore.selectedCompany
      ? AuthStore.groups.filter(doc => {
        const item = doc.data()
        if (!item) return false
        if (!AuthStore.selectedCompany) return false
        return item.company.id === AuthStore.selectedCompany.id
      })
      : AuthStore.groups
    return [{ value: '', text: '전체' }, ...filteredGroups.map(doc => {
      return { value: doc.id, text: doc.data()?.name || '' }
    })]
  }

  get selectedGroup (): string {
    return AuthStore.selectedGroup ? AuthStore.selectedGroup.id : ''
  }

  setGroup (id: string): void {
    AuthStore.setGroup(AuthStore.groups.find(item => item.id === id) || null)
  }
}
