











import { Component, Vue } from 'vue-property-decorator'
import AuthStore from '@/store/AuthStore'

@Component<CompanySelect>({
})
export default class CompanySelect extends Vue {
  get selectCompanies (): { value: string, text: string }[] {
    return [{ value: '', text: '전체' }, ...AuthStore.companies.map(doc => {
      return { value: doc.id, text: doc.data()?.name || '' }
    })]
  }

  get selectedCompany (): string {
    return AuthStore.selectedCompany ? AuthStore.selectedCompany.id : ''
  }

  setCompany (id: string): void {
    AuthStore.setCompany(AuthStore.companies.find(item => item.id === id) || null)
  }
}
