











































import { Component, Vue, Prop } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { Device } from '@/models/device'
import DisplayTime from '@/components/common/DisplayTime.vue'
import DisplayPhoneNumber from '@/components/common/DisplayPhoneNumber.vue'
import AuthStore from '@/store/AuthStore'
import DeviceInfo from '@/components/common/DeviceInfo.vue'

@Component({
  components: {
    DisplayTime,
    DisplayPhoneNumber,
    DeviceInfo
  }
})
export default class DeviceItem extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.QueryDocumentSnapshot<Device>

  get item (): Device {
    return this.doc.data()
  }

  get toDashboard (): string {
    return `/dashboard/${this.doc.id}`
  }

  get group (): string | null {
    if (!this.item.group) return null
    const group = AuthStore.groups.find(item => item.id === this.item.group?.id)
    if (!group) return null
    return group.data()?.name || null
  }

  remove (): void {
    const r = confirm('정말 삭제 하시겠습니까?')
    if (!r) return
    this.doc.ref.delete()
  }
}
